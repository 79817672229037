var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fr-table",class:_vm.infosDgv.resource == undefined ? '' : 'padding'},[_c('table',{ref:"table",on:{"scroll":function($event){return _vm.handleScroll($event)}}},[_c('thead',{attrs:{"id":"tabhead"}},[_c('tr',_vm._l((_vm.fields),function(field){return _c('th',{key:'header-' + field.key,staticClass:"header sticky-bar",class:{ 'header--sorted': field.key === _vm.sortBy, 'filter--filled': _vm.getFilter(field.key) },attrs:{"scope":"col"}},[_c('div',{staticClass:"fr-grid-row fr-grid-row--middle no-wrap",on:{"mouseover":function($event){_vm.hoverArrow = true},"mouseleave":function($event){_vm.hoverArrow = false}}},[(field.key === _vm.sortBy)?_c('div',{staticClass:"fr-col-auto",on:{"click":function($event){return _vm.sortbyfield(field.key)}}},[_c('span',{staticClass:"fr-icon-arrow-down-line text-label-blue-cumulus",class:{
                  'fr-icon-arrow-down-line': _vm.sortDesc,
                  'fr-icon-arrow-up-line': !_vm.sortDesc,
                },attrs:{"aria-hidden":"true"}})]):_vm._e(),_c('div',{staticClass:"fr-col style-header-col",class:{ 'text-label-blue-cumulus': field.key === _vm.sortBy || _vm.getFilter(field.key) },on:{"click":function($event){return _vm.sortbyfield(field.key)}}},[_vm._v(" "+_vm._s(field.label)+" ")]),_c('div',{staticClass:"style-header-col fr-col-auto filter-icon"},[_c('button',{class:{ 'text-label-blue-cumulus': field.key === _vm.sortBy },attrs:{"data-fr-opened":"false","aria-controls":'fr-modal-' + field.key}},[(_vm.columnsInfos && _vm.columnsInfos[field.key])?_c('span',[_c('svg',{attrs:{"width":"13","height":"13","viewBox":"0 0 13 13","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M11.25 2.5V3.5H10.75L8.25 7.25V11.5H5.25V7.25L2.75 3.5H2.25V2.5H11.25ZM3.952 3.5L6.25 6.947V10.5H7.25V6.947L9.548 3.5H3.952Z","fill":"#929292"}})])]):_vm._e()])])]),_c('FieldModal',{attrs:{"id":'fr-modal-' + field.key,"field":field}})],1)}),0)]),_c('tbody',{ref:"body",attrs:{"id":"body"}},_vm._l((_vm.rows),function(row,index){return _c('tr',{key:row[0]},_vm._l((_vm.fields),function(field){return _c('td',{key:'row-' + index + '-' + field.key,on:{"mouseleave":_vm.hideTooltips,"mouseenter":function($event){return _vm.loadTooltip(field.key, index)},"click":function($event){return _vm.loadTooltip(field.key, index)}}},[_c('div',{staticClass:"cell"},[(
                _vm.columnsInfos &&
                _vm.columnsInfos[field.key] &&
                _vm.columnsInfos[field.key]['format'] === 'url'
              )?_c('span',{class:_vm.getCellColor(field.key, row[field.key])},[_c('a',{attrs:{"href":row[field.key]}},[_vm._v(_vm._s(row[field.key]))])]):_c('span',{class:_vm.getCellColor(field.key, row[field.key])},[_vm._v(" "+_vm._s(row[field.key])+" ")])]),(
              _vm.columnsInfos[field.key] &&
              _vm.isTooltipActive(field.key, index) &&
              _vm.displayTooltip &&
              _vm.messageBox != ''
            )?[(_vm.columnsInfos[field.key]['format'] === 'siren')?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ soit un numéro d'entreprise (numéro Siren)","content":'Entreprise : ' + _vm.messageBox,"link":"En savoir plus sur cette entreprise","linkHref":_vm.gotoAE('siren', row[field.key])}}):_vm._e(),(_vm.columnsInfos[field.key]['format'] === 'siret')?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ soit un numéro d'entreprise (numéro Siret)","content":'Entreprise : ' + _vm.messageBox,"link":"En savoir plus sur cette entreprise","linkHref":_vm.gotoAE('siret', row[field.key])}}):(
                _vm.columnsInfos[field.key]['format'] == 'code_departement'
              )?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ soit un code de département","content":'Département : ' + _vm.messageBox}}):(_vm.columnsInfos[field.key]['format'] === 'code_region')?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ soit un code de région","content":'Région : ' + _vm.messageBox}}):(_vm.columnsInfos[field.key]['format'] == 'departement')?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ soit un nom de département","content":'Code Département : ' + _vm.messageBox}}):(_vm.columnsInfos[field.key]['format'] === 'region')?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ soit un nom de région","content":'Code Région : ' + _vm.messageBox}}):(
                _vm.columnsInfos[field.key]['format'] === 'code_commune_insee'
              )?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ soit un code commune","content":'Commune : ' + _vm.messageBox}}):(_vm.columnsInfos[field.key]['format'] === 'code_postal')?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ soit un code postal","content":'Commune possibles : ' + _vm.messageBox}}):(_vm.columnsInfos[field.key]['format'] === 'commune')?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ soit une commune","content":'Code commune : ' + _vm.messageBox}}):(_vm.columnsInfos[field.key]['format'] === 'email')?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ soit un email","link":"Ecrire un mail","content":"","linkHref":'mailto:' + row[field.key]}}):(
                _vm.columnsInfos[field.key]['format'] === 'longitude_wgs' ||
                _vm.columnsInfos[field.key]['format'] ===
                  'longitude_wgs_fr_metropole'
              )?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ soit une coordonnée de longitude. Nous avons également trouvé une coordonnée de latitude dans le fichier.","link":"Voir sur une carte","content":'La localisation semble être à ' + _vm.messageBox,"linkHref":_vm.banurl}}):(
                _vm.columnsInfos[field.key]['format'] === 'latitude_wgs' ||
                _vm.columnsInfos[field.key]['format'] ===
                  'latitude_wgs_fr_metropole'
              )?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ soit une coordonnée de latitude. Nous avons également trouvé une coordonnée de longitude dans le fichier.","link":"Voir sur une carte","content":'La localisation semble être à ' + _vm.messageBox,"linkHref":_vm.banurl}}):(_vm.columnsInfos[field.key]['format'] === 'latlon_wgs')?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ contienne des coodonnées géographiques.","link":"Voir sur une carte","content":'La localisation semble être à ' + _vm.messageBox,"linkHref":_vm.banurl}}):(_vm.columnsInfos[field.key]['format'] === 'adresse')?_c('Tooltip',{attrs:{"explanation":"Il semblerait que ce champ soit une adresse.","link":"Voir sur une carte","content":'Adresse consolidée : ' + _vm.messageBox,"linkHref":_vm.banurl}}):_vm._e()]:_vm._e()],2)}),0)}),0),(_vm.rows.length >= 10)?_c('button',{staticClass:"fr-tag fr-tag--sm",on:{"click":function($event){return _vm.forceUserChangePage()}}},[_vm._v(" Charger plus de données ")]):_vm._e(),(_vm.rows.length === 0 && _vm.filters.left > 0)?_c('div',[_c('br'),_c('p',[_vm._v(" Basé sur les filtres appliqués, l'explorateur ne trouve aucun résultat dans le fichier. ")])]):_vm._e(),(_vm.rows.length < 10)?_c('div',{staticClass:"messageNoResults"}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }